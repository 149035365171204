import axios from "axios";
import {
    POST_NEW_NOTICE,
    POST_NEW_PROGRAM,
    GET_NOTICES,
    GET_NOTICE,
    GET_PROGRAMS,
    GET_PROGRAM,
    ADMIN_LOGIN_SUCCESS
} from "./Types";

import Swal from 'sweetalert2';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const postNewNotice = (payload) => {
    return async (dispatch) => {
        try {
            console.log("Payload enviado:", payload);
            const response = await axios.post("/diario", payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    //'Authorization': `Bearer ${token}`
                }
            });
            dispatch({
                type: POST_NEW_NOTICE,
                payload: response.data,
            });

             // Mostrar una alerta de éxito
            Swal.fire({
                       title: '¡Éxito!',
                       text: 'La noticia se ha subido correctamente.',
                       icon: 'success',
                       confirmButtonText: 'OK'
                      });

        } catch (error) {
            console.error("Error posting notice:", error.response ? error.response.data : error.message);

        // Mostrar una alerta de error
            Swal.fire({
                        title: 'Error',
                        text: 'Hubo un problema al subir la noticia.',
                        icon: 'error',
                        confirmButtonText: 'OK'
                      });

            throw error; // Re-throw the error to be handled in the component if needed
        }
    };
};

export const postNewProgram = (payload) => {
    return async (dispatch) => {
        try {
            const response = await axios.post("/radio", payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            dispatch({
                type: POST_NEW_PROGRAM,
                payload: response.data,
            });

                        // Mostrar una alerta de éxito
                        Swal.fire({
                            title: '¡Éxito!',
                            text: 'El programa de radio se ha subido correctamente.',
                            icon: 'success',
                            confirmButtonText: 'OK'
                        });

        } catch (error) {
            console.error("Error posting program:", error.response ? error.response.data : error.message);

                        // Mostrar una alerta de error
                        Swal.fire({
                            title: 'Error',
                            text: 'Hubo un problema al subir el programa.',
                            icon: 'error',
                            confirmButtonText: 'OK'
                        });

            throw error; // Re-throw the error to be handled in the component if needed
        }
    };
};

export const getNotices = () => {
    return async function (dispatch) {
        try {
            const response = await fetch('/diario/noticias');
            const data = await response.json();
            console.log(data);
            dispatch({
                type: GET_NOTICES,
                payload: data,
            })
        } catch (error) {
            console.error(error);
        }
    }
}

export const getNotice = (id) => {
    try {
        return async function (dispatch) {
            let json = await axios.get(`/diario/noticias/${id}`);
            console.log("console.log", json)

            return dispatch({
                type: GET_NOTICE,
                payload: json.data,
            })
        }

    } catch (error) {
        console.log("error-post", error)

    }

}

export const getPrograms = () => {
    return async function (dispatch) {
        try {
            const response = await fetch('/radio/programas');
            const data = await response.json();
            console.log(data);
            dispatch({
                type: GET_PROGRAMS,
                payload: data,
            })
        } catch (error) {
            console.error(error);
        }
    }
}

export const getProgram = (id) => {
    try {
        return async function (dispatch) {
            let json = await axios.get(`/radio/programas/${id}`);

            return dispatch({
                type: GET_PROGRAM,
                payload: json.data,
            })
        }

    } catch (error) {
        console.log("error-post", error)

    }

}


export const adminLogin = (username, password, navigate) => async (dispatch) => {
    try {
        console.log("Attempting to login with", { email: username, password });
        const response = await axios.post('/user/login', { email: username, password });
        const { token, role } = response.data;

        console.log("Login response data:", response.data);

        // Store the token and role in cookies
        cookies.set('token', token, { path: '/' });
        cookies.set('role', role, { path: '/' });

        if (role === 'Admin') {
            dispatch({ type: ADMIN_LOGIN_SUCCESS });
            navigate('/admin-dashboard');
        } else {
            alert('No es usuario Admin');
        }
    } catch (error) {
        console.error("Error during authentication:", error);
        alert('Error en la autenticación');
    }
};