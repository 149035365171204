import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import "./Slider.css";
import { Carousel } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import { getNotices } from "../../Redux/Actions/Index";


function Slider() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const notices = useSelector(state => state.allNotices);

    useEffect(() => {
        dispatch(getNotices());
        // Agregar console.log para verificar si las noticias se están obteniendo correctamente
        console.log("Fetched notices: ", notices);
    }, [dispatch]);

    const handleReadMore = (id) => {
        console.log(`Navigating to news with ID: ${id}`); // Verificar el ID de la noticia que se va a leer
        navigate(`/noticia/${id}`);
        };

    const truncateDescription = (description) => {
        const maxLength = 60; // Número máximo de caracteres para una línea
        if (description.length > maxLength) {
            return description.substring(0, maxLength) + '...';
        }
        return description;
    };


    return (
       <div className="carousel-container">
        <Carousel className="carousel" controls={true} indicators={true} interval={null}>
            {notices.slice(0, 3).map((notice, index) => (  // Toma las 3 noticias más recientes
                <Carousel.Item key={index} className="carousel-inner">
                    {console.log(`Rendering image URL for slide ${index + 1}:`, notice.imageUrl)}
                    <img
                        className="d-block w-100"
                        src={notice.imageUrl}
                        alt={`Slide ${index + 1}`}
                    />
                    <Carousel.Caption className="carousel-caption">
                            <h3>{notice.title}</h3>
                            <p>{truncateDescription(notice.description)}</p>
                        <Button variant="dark" onClick={() => handleReadMore(notice.id)}>Ver Más</Button>
                    </Carousel.Caption>
                </Carousel.Item>
            ))}
        </Carousel>
       </div>  
    );
}

export {Slider};