import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Form, Modal } from 'react-bootstrap';
import logo from '../Assets/logo-home.jpg';
import logoDiarioSolo from '../Assets/logoDiarioSolo.jpg';
import logoRadioSolo from '../Assets/logoRadioSolo.jpg';
import logoConcursoSolo from '../Assets/logoConcursoSolo.jpg';
import { adminLogin } from '../../Redux/Actions/Index';
import './Navbar.css';

function Navbar() {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isAuthenticated = useSelector(state => state.isAuthenticated);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleClick = (e) => {
        e.preventDefault();
        dispatch({type: 'SET_CURRENT_PAGE', payload: 1});
        navigate('/');
    };

    const handleNavigate = (path) => {
        navigate(path);
    };

    const handleLogin = () => {
        dispatch(adminLogin(username, password, navigate));
        setShowLoginModal(false);
    };


    return (
        <div className="navbar">
            <div className='logo-container'>
                    <img
                        className="logo-laIdea"
                        src={logo}
                        alt="Home Logo"
                        onClick={handleClick}
                    />
            </div>
            {location.pathname === '/' && (
                <div className="navbar-buttons">
                    <Button variant="link" onClick={() => handleNavigate('/Nosotros')}>Nosotros</Button>
                    <Button variant="link" onClick={() => handleNavigate('/Contactanos')}>Contactanos</Button>
                    {isAuthenticated ? (
                        <Button variant="link" onClick={() => handleNavigate('/admin-dashboard')}>Dashboard</Button>
                    ) : (
                        <Button variant="link" onClick={() => setShowLoginModal(true)}>Iniciar Sesión</Button>
                    )}
                </div>
            )}

            {location.pathname === '/Diario' && (
                <div className='section-logo'>
                    <img 
                        className="section-image" 
                        src={logoDiarioSolo} 
                        alt="Diario Section" 
                    />
                </div>
            )}
            {location.pathname === '/Radio' && (
                <div className='section-logo'>
                    <img 
                        className="section-image" 
                        src={logoRadioSolo} 
                        alt="Radio Section"  
                    />
                </div>
            )}
            {location.pathname === '/Concurso' && (
                <div className='section-logo'>
                    <img 
                        className="section-image" 
                        src={logoConcursoSolo} 
                        alt="Concurso Section"  
                    />
                </div>
            )}
            <Modal show={showLoginModal} onHide={() => setShowLoginModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Iniciar Sesión</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Usuario</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Contraseña</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowLoginModal(false)}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={handleLogin}>
                        Iniciar Sesión
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Navbar;