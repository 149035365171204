import React from "react";
import "./Footer.css";





function Footer() {



    return (

        <footer className="footer">

            <div className="footer-content">

                <h3>Multimedios La Idea</h3>

                <p>
                    Remedios de Escalada
                    - Buenos Aires
                    Cel.: (15) 5064-6321
                    periodicolaidea1915@gmail.com
                </p>

                <ul className="socials">

                    <li>
                        <a href="https://www.facebook.com/multimedios.laidea" target="_blank" rel="noreferrer" className="facebook">
                            <i className="fab fa-facebook-f"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/laidea.1915" target="_blank" rel="noreferrer" className="instagram">
                        <i className="fab fa-instagram"></i>
                        </a>
                    </li>

                </ul>

            </div>

            <div className="footer-bottom">

            <p>copyright &copy; 2024. designed by <span><a href="mailto:javlucky83@gmail.com">Javlucky</a></span></p>

            </div>

        </footer>

    );

};


export default Footer;