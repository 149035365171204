import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getNotices } from '../../Redux/Actions/Index';
import { Dropdown, DropdownButton, Button, Container, Row, Col } from 'react-bootstrap';
import PaginationComponent from '../../components/Pagination/Pagination'
import './Diario.css';
import moment from 'moment';

function Diario() {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const articlesPerPage = 5;
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const articles = useSelector((state) => state.allNotices);

    useEffect(() => {
        dispatch(getNotices()); // Llama la acción para obtener las noticias
    }, [dispatch]);

     // Convierte y ordena las fechas antes de la paginación
     const sortedArticles = articles.slice().sort((a, b) => {
        const dateA = moment(a.date, 'DD-MM-YYYY');
        const dateB = moment(b.date, 'DD-MM-YYYY');
        return dateB - dateA; // Ordena de más reciente a más antigua
    });

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const indexOfLastArticle = currentPage * articlesPerPage;
    const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
    const currentArticles = sortedArticles.slice(indexOfFirstArticle, indexOfLastArticle);
    const totalPages = Math.ceil(articles.length / articlesPerPage);

    const categories = [
        "Notas de tapa", "Editorial", "Institucionales", 
        "Educación y Cultura", "Espectáculos", "Deportes", 
        "Interés General", "Notas de Opinión"
    ];

    const toggleDropdown = () => {
      setDropdownOpen(!dropdownOpen);
    };

    const handleReadMore = (id) => {
    navigate(`/noticia/${id}`);
    };

    return (
      <Container className={`diario-view ${dropdownOpen ? 'dropdown-open' : ''}`}>
          <div className="banner">
              <Row className="align-items-center">
                  <Col md={3}>
                      <DropdownButton
                          id="dropdown-basic-button"
                          variant="primary"
                          title="Categorías"
                          className="category-dropdown"
                          onClick={toggleDropdown}
                      >
                          {categories.map((category, index) => (
                              <Dropdown.Item key={index} href={`#${category.toLowerCase().replace(/ /g, '-')}`}>
                                  {category}
                              </Dropdown.Item>
                          ))}
                      </DropdownButton>
                  </Col>
                  <Col md={9} className="text-center">
                      <h2 className="banner-title">La Idea Periódico</h2>
                  </Col>
              </Row>
          </div>
          <Row> 
                <Col md={12} className="articles">
                    {currentArticles && currentArticles.length > 0 ? (
                        currentArticles.map((article) => (
                            <div key={article.id} className="article">
                                <h3>{article.title}</h3>
                                <img src={article.imageUrl} alt={article.title} className="img-fluid mb-3" />
                                <p><small>{article.date}</small></p>
                                <p>{article.description}</p>
                                <Button variant="dark" onClick={() => handleReadMore(article.id)}>Ver Más</Button>
                            </div>
                        ))
                    ) : (
                        <p>No hay artículos disponibles.</p>
                    )}
                </Col>
          </Row>
          <PaginationComponent currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
      </Container>
  );
}

export default Diario;