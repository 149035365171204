import { useState } from "react";
import { useDispatch } from "react-redux";
import { postNewNotice } from '../../Redux/Actions/Index';
import { PlusOutlined } from '@ant-design/icons';
import { Form, Input, Button, Upload, DatePicker } from 'antd';
import moment from 'moment';
import "./AdminDashboard.css";

function FormularioCrearNoticia() {
    const dispatch = useDispatch();
    const [fileList, setFileList] = useState([]);
    const [inputNoticia, setInputNoticia] = useState({
        title: "",  // Cambiado de "titulo" a "title"
        description: "",  // Cambiado de "descripcion" a "description"
        date: null  // Cambiado de "fecha" a "date"
    });

    const handleInputChange = (e) => {
        setInputNoticia({
            ...inputNoticia,
            [e.target.name]: e.target.value
        });
    };

    const handleDateChange = (date, dateString) => {
        setInputNoticia({
            ...inputNoticia,
            date: dateString
        });
    };

    const handleFileChange = ({ fileList }) => {
        setFileList(fileList);
    };

    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append('title', inputNoticia.title);  // Cambiado de "titulo" a "title"
        formData.append('description', inputNoticia.description);  // Cambiado de "descripcion" a "description"
        formData.append('date', inputNoticia.date);  // Cambiado de "fecha" a "date"
        if (fileList.length > 0) {
            formData.append('imageUrl', fileList[0].originFileObj);
        }

        try {
            await dispatch(postNewNotice(formData));
            // Clear the form
            setInputNoticia({
                title: "",  // Cambiado de "titulo" a "title"
                description: "",  // Cambiado de "descripcion" a "description"
                date: null  // Cambiado de "fecha" a "date"
            });
            setFileList([]);
        } catch (error) {
            console.error("Error posting new notice:", error);
        }
    };

    return (
        <div className="form-ant">
            <Form
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                style={{ maxWidth: 600 }}
                onFinish={handleSubmit}
            >
                <h1>Subir Noticia</h1>

                <Form.Item label="Titulo">
                    <Input
                        onChange={handleInputChange}
                        name="title"
                        placeholder="Escriba el titulo"
                        value={inputNoticia.title}
                    />
                </Form.Item>

                <Form.Item label="Imagen">
                    <Upload
                        listType="picture-card"
                        fileList={fileList}
                        onChange={handleFileChange}
                        beforeUpload={() => false}
                    >
                        {fileList.length < 1 && (
                            <div>
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>Upload</div>
                            </div>
                        )}
                    </Upload>
                </Form.Item>

                <Form.Item label="Descripcion">
                    <Input.TextArea
                        rows={4}
                        onChange={handleInputChange}
                        name="description"
                        placeholder="Descripcion"
                        value={inputNoticia.description}
                    />
                </Form.Item>

                <Form.Item label="Fecha">
                    <DatePicker
                        format="DD/MM/YYYY"
                        onChange={handleDateChange}
                        value={inputNoticia.date ? moment(inputNoticia.date, "DD/MM/YYYY") : null}
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Guardar
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export { FormularioCrearNoticia };

