import React from "react";
import "./Carousel.css";
import { Carousel } from "antd";
import talleres from '../Assets/talleres.jpg';
import villegas from '../Assets/villegas.png';
import alberdi from '../Assets/alberdi.png';
import parroquia from '../Assets/parroquia.jpg';
import rotary from '../Assets/rotary.jpg';
import escalada from '../Assets/escalada.jpg';
import scout from '../Assets/scout.jpg';
import italiana from '../Assets/italiana.jpg';
import pupi from '../Assets/pupi.png';
import credicoop from '../Assets/credicoop.png';
import cultural from '../Assets/cultural.jpg';

function LogoCarousel() {
  
  const carouselItems = [
    {src: talleres, link: 'https://www.threads.net/@clubtalleres?xmt=AQGz4r1uklofe3lmNWSYEmTyxQZsP3helLNbP-UfGeBul5M', alt: 'Institución 1'},
    {src: villegas, link: 'https://www.instagram.com/clubvillegas/', alt: 'Institución 2'},
    {src: alberdi, link: 'https://www.instagram.com/bibpopalberdi/', alt: 'Institución 2'},
    {src: parroquia, link: 'https://www.instagram.com/parroquiaremediosdeescalada/#', alt: 'Institución 2'},
    {src: rotary, link: 'https://www.instagram.com/rotaryremediosdeescalada/#', alt: 'Institución 2'},
    {src: escalada, link: 'https://www.instagram.com/escaladadepie_ong/#', alt: 'Institución 2'},
    {src: scout, link: 'https://www.instagram.com/gruposcoutremedios/#', alt: 'Institución 2'},
    {src: italiana, link: 'https://www.instagram.com/sociedaditalianaescalada/#', alt: 'Institución 2'},
    {src: pupi, link: 'https://www.instagram.com/fundacionpupi/#', alt: 'Institución 2'},
    {src: credicoop, link: 'https://www.instagram.com/banco.credicoop/#', alt: 'Institución 2'},
    {src: cultural, link: 'https://www.instagram.com/elbarriocultural/#', alt: 'Institución 2'}
  ];

    // Agrupar los logos en grupos de tres
    const groupedItems = [];
    for (let i = 0; i < carouselItems.length; i += 3) {
      groupedItems.push(carouselItems.slice(i, i + 3));
    }

    return (
      <div className="logo-carousel">
        <Carousel autoplay pauseOnHover speed={1500}>
          {groupedItems.map((group, index) => (
            <div key={index} className="carousel-slide">
              <div className="carousel-group">
                {group.map((item, idx) => (
                  <a key={idx} href={item.link} target="_blank" rel="noopener noreferrer">
                    <img src={item.src} alt={item.alt} />
                  </a>
                ))}
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    );
};

export default LogoCarousel;