import React, { useState } from 'react';
import './Edicion.css';
import { Form, Button, Modal } from 'react-bootstrap';
import cantar from '../../components/Assets/cantar.jpg';
import antologia from '../../components/Assets/antologia.jpg';
import semillero from '../../components/Assets/semillero.jpg';

const Edicion = () => {
  const [formData, setFormData] = useState({
    nombre: '',
    telefono: '',
    direccion: '',
    localidad: '',
    email: '',
    comentarios: ''
  });

  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState('');

  const handleImageClick = (imageSrc) => {
    setModalImage(imageSrc);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalImage('');
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:3001/edicion', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const result = await response.json();
      alert(result.message);
    } catch (error) {
      console.error('Error al enviar el formulario:', error);
    }
  };

  return (
    <div className="edicion-container">
      <h2>Contacto</h2>
      {/* <p>Dir: Remedios de Escalada</p> */}
      <p>Cel.: (15) 5064-6321</p>
      <p>E-mail: periodicolaidea1915@gmail.com</p>
      <p>Completando el formulario podrás solicitar cotización para el diseño que quieras realizar.</p>

      <div className="image-gallery">
        <img src={cantar} alt="Imagen 1" onClick={() => handleImageClick(cantar)} />
        <img src={antologia} alt="Imagen 2" onClick={() => handleImageClick(antologia)} />
        <img src={semillero} alt="Imagen 3" onClick={() => handleImageClick(semillero)} />
      </div>
      
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="nombre">
          <Form.Label>Nombre y Apellido</Form.Label>
          <Form.Control 
            type="text" 
            placeholder="Ingresa tu nombre y apellido" 
            value={formData.nombre} 
            onChange={handleChange} 
          />
        </Form.Group>

        <Form.Group controlId="telefono">
          <Form.Label>Teléfono</Form.Label>
          <Form.Control 
            type="tel" 
            placeholder="Ingresa tu teléfono" 
            value={formData.telefono} 
            onChange={handleChange} 
          />
        </Form.Group>

        <Form.Group controlId="direccion">
          <Form.Label>Dirección</Form.Label>
          <Form.Control 
            type="text" 
            placeholder="Ingresa tu dirección" 
            value={formData.direccion} 
            onChange={handleChange} 
          />
        </Form.Group>

        <Form.Group controlId="localidad">
          <Form.Label>Localidad</Form.Label>
          <Form.Control 
            type="text" 
            placeholder="Ingresa tu localidad" 
            value={formData.localidad} 
            onChange={handleChange} 
          />
        </Form.Group>

        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control 
            type="email" 
            placeholder="Ingresa tu email" 
            value={formData.email} 
            onChange={handleChange} 
          />
        </Form.Group>

        <Form.Group controlId="comentarios">
          <Form.Label>Comentarios</Form.Label>
          <Form.Control 
            as="textarea" 
            rows={3} 
            value={formData.comentarios} 
            onChange={handleChange} 
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Enviar
        </Button>
      </Form>
      
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Body>
          <img src={modalImage} alt="Modal" className="modal-image" />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Edicion;