import React, { useState } from 'react';
import './Contactanos.css';
import { Form, Button } from 'react-bootstrap';

const Contactanos = () => {

  const [formData, setFormData] = useState({
    nombre: '',
    telefono: '',
    direccion: '',
    localidad: '',
    email: '',
    suscripcion: false,
    comentarios: ''
  });

  const handleChange = (e) => {
    const { id, value, checked, type } = e.target;
    setFormData({
      ...formData,
      [id]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:3001/contactanos', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const result = await response.json();
      alert(result.message);
    } catch (error) {
      console.error('Error al enviar el formulario:', error);
    }
  };

  return (
    <div className="contactanos-container">
      <h2>Contacto</h2>
      {/* <p>Dir: Dr. Melo 4571 – Remedios de Escalada – C.P. 1826</p> */}
      <p>Cel.: (15) 5064-6321</p>
      <p>E-mail: periodicolaidea1915@gmail.com</p>
      <p>Completando el formulario podrás solicitar información acerca nosotros además de suscribirte a nuestro periódico y recibirlo en tu domicilio.</p>
      
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="nombre">
          <Form.Label>Nombre y Apellido</Form.Label>
          <Form.Control 
            type="text" 
            placeholder="Ingresa tu nombre y apellido" 
            value={formData.nombre} 
            onChange={handleChange} 
          />
        </Form.Group>

        <Form.Group controlId="telefono">
          <Form.Label>Teléfono</Form.Label>
          <Form.Control 
            type="tel" 
            placeholder="Ingresa tu teléfono" 
            value={formData.telefono} 
            onChange={handleChange} 
          />
        </Form.Group>

        <Form.Group controlId="direccion">
          <Form.Label>Dirección</Form.Label>
          <Form.Control 
            type="text" 
            placeholder="Ingresa tu dirección" 
            value={formData.direccion} 
            onChange={handleChange} 
          />
        </Form.Group>

        <Form.Group controlId="localidad">
          <Form.Label>Localidad</Form.Label>
          <Form.Control 
            type="text" 
            placeholder="Ingresa tu localidad" 
            value={formData.localidad} 
            onChange={handleChange} 
          />
        </Form.Group>

        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control 
            type="email" 
            placeholder="Ingresa tu email" 
            value={formData.email} 
            onChange={handleChange} 
          />
        </Form.Group>

        <Form.Group controlId="suscripcion">
          <Form.Check 
            type="checkbox" 
            label="Deseo suscribirme al periódico" 
            checked={formData.suscripcion} 
            onChange={handleChange} 
          />
        </Form.Group>

        <Form.Group controlId="comentarios">
          <Form.Label>Comentarios</Form.Label>
          <Form.Control 
            as="textarea" 
            rows={3} 
            value={formData.comentarios} 
            onChange={handleChange} 
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Enviar
        </Button>
      </Form>
    </div>
  );
};

export default Contactanos;
