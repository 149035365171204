import React from 'react';
import { Container, Row, Col, Accordion } from 'react-bootstrap';
import './Concurso.css';

const Concurso = () => {
  return (
<div className="concurso-container">
      <Container>
        <Row className="justify-content-center">
          <Col md={8}>
            <div className="concurso-content">
              <h2 className="section-title">Concurso Nacional de Poesía, Cuento Breve, Fotografía y Dibujo</h2>
              <p>
                Está abierta la inscripción para el 26 to. Concurso Nacional de Fotografía y Dibujo para Adultos (18 años de edad en adelante), Adolescentes (12 a 17 años de edad) y Niños (8 a 11 años de edad) que organiza este Multimedios, cuya denominación es LA IDEA 109º Aniversario.
              </p>
              <p>
                La participación es totalmente libre, debiendo el participante adulto abonar $ 1.300 (los suscriptores $ 1.000) por derecho de inscripción; adolescentes y niños SIN CARGO. Los tres mejores trabajos de cada disciplina obtendrán una medalla y un diploma -los que serán entregados el 27 de setiembre de 2024- y la publicación de sus trabajos en UNA ANTOLOGIA. El colegio al que pertenezca cada uno de los premiados en Niños y Adolescentes recibirá una bandera argentina. La recepción de los mismos (que deberán estar firmados con seudónimo) vence EL 19 DE JULIO DE 2024, fecha a partir de la cual se comenzará a evaluar por jurados especializados y nuestro director.
              </p>
              <p>
                En Fotografía, las medidas no superarán los 20 x 25 cm., presentándose 3 ORIGINALES (NO FOTOCOPIAS; los trabajos presentados en fotocopia quedarán fuera de concurso) en color, blanco y negro o sepia, acompañados de una hoja con la explicación del efecto buscado en la toma y su título. También podrán ser presentados en formato digital.
              </p>
              <p>
                En Dibujo, la medida no podrá superar la hoja oficio, presentándose original y dos copias, en tinta, acompañados de una hoja con la explicación del motivo buscado y su título. También podrán ser presentados en formato digital.
              </p>
              <p>
                En las 2 disciplinas los participantes podrán presentar hasta 3 trabajos cada uno, debiendo (los adultos) abonar por cada uno. Los mismos deberán estar acompañados por una hoja en la que figuren los datos personales del autor (nombre, edad, domicilio, número de documento, teléfono, seudónimo, etc.).
              </p>
              <p>
                ACLARAMOS: los datos enviados por las escuelas, deberán ser entregados en papel carta con todos los datos solicitados, incluida la edad.
              </p>
              <p>
                Para mayor información, los interesados deberán comunicarse las 24 horas al teléfono: (15) 5064-6321; o vía mail a mensajes@multimedioslaidea.com.ar; o visitando nuestro Portal: www.multimedioslaidea.com.ar (ÍCONO CONCURSOS).
              </p>
              <p>
                NOTA: TODOS LOS TRABAJOS DEBERÁN SER INDIVIDUALES. LOS ORIGINALES NO SERAN DEVUELTOS.
              </p>
            </div>
          </Col>
        </Row>

    {/* Sección de Preguntas Frecuentes */}
    <div>
        <Row className="mt-4">
          <Col md={8}>
            <h3 className="section-title">Preguntas Frecuentes</h3>
            <Accordion>
              <Accordion.Item variant="link" eventKey="0">
                  <Accordion.Header>
                    ¿Cuáles son los requisitos para participar en el concurso?
                  </Accordion.Header>
                  <Accordion.Body>
                    Los requisitos varían según la categoría de participación. Por favor, consulta las bases del concurso para más detalles.
                  </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item variant="link" eventKey="1">
                  <Accordion.Header>
                    ¿Cuál es la fecha límite para enviar los trabajos?
                  </Accordion.Header>
                  <Accordion.Body>
                    La fecha límite para la recepción de trabajos es el 19 de julio de 2024.
                  </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item variant="link" eventKey="2">
                  <Accordion.Header>
                    ¿Cómo puedo obtener más información sobre el concurso?
                  </Accordion.Header>
                  <Accordion.Body>
                    Para más información, puedes comunicarte las 24 horas al teléfono: (15) 5064-6321; o vía mail a mensajes@multimedioslaidea.com.ar; o visitando nuestro Portal: www.multimedioslaidea.com.ar.
                  </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
        </div>
      </Container>
</div>
  );
}

export default Concurso;