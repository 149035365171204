import { useState } from "react";
import { useDispatch } from 'react-redux';
import { postNewProgram } from '../../Redux/Actions/Index';
import { PlusOutlined } from '@ant-design/icons';
import { Form, Input, Button, Upload, DatePicker } from 'antd';
import moment from 'moment';
import Swal from 'sweetalert2';
import "./AdminDashboard.css";

function FormularioCrearPrograma() {
    const dispatch = useDispatch();
    const [fileList, setFileList] = useState([]);
    const [inputPrograma, setInputPrograma] = useState({
        title: "",
        description: "",
        date: null
    });

    const handleInputChange = (e) => {
        setInputPrograma({
            ...inputPrograma,
            [e.target.name]: e.target.value
        });
    };

    const handleDateChange = (date, dateString) => {
        setInputPrograma({
            ...inputPrograma,
            date: dateString
        });
    };

    const handleFileChange = ({ fileList }) => {
        const file = fileList[0];
        
        // Verifica el tamaño del archivo
        // if (file && file.size > 10485760) {
        //     Swal.fire({
        //         icon: 'error',
        //         title: 'Archivo demasiado grande',
        //         text: 'El archivo de audio debe ser menor a 10MB.',
        //     });
        //     setFileList([]);
        //     return;
        // }
    
        // Verifica el formato del archivo
        const allowedFormats = ['audio/mpeg', 'audio/wav', 'audio/ogg']; // Añadir otros formatos permitidos si es necesario
        if (file && !allowedFormats.includes(file.type)) {
            Swal.fire({
                icon: 'error',
                title: 'Formato no compatible',
                text: 'El formato del archivo no es compatible. Por favor, sube un archivo en formato MP3, WAV, OGG, etc.',
            });
            setFileList([]); // Clear the file list
            return;
        }
    
        setFileList(fileList);
    };

    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append('title', inputPrograma.title);
        formData.append('description', inputPrograma.description);
        formData.append('date', inputPrograma.date);
        if (fileList.length > 0) {
            formData.append('audioUrl', fileList[0].originFileObj);
        }

        try {
            await dispatch(postNewProgram(formData));
            setInputPrograma({
                title: "",
                description: "",
                date: null
        });
        setFileList([]);
        } catch (error) {
        console.error("Error posting new program:", error);
        }
    };

    return (
        <div className="form-ant">
            <Form
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                style={{ maxWidth: 600 }}
                onFinish={handleSubmit}
            >
                <h1>Subir Programa</h1>

                <Form.Item label="Titulo">
                    <Input 
                        onChange={handleInputChange}
                        name="title" 
                        placeholder="escriba el titulo" 
                        value={inputPrograma.title} 
                    />
                </Form.Item>

                <Form.Item label="Audio">
                    <Upload 
                        fileList={fileList}
                        onChange={handleFileChange}
                        beforeUpload={() => false}
                   >
                        {fileList.length < 1 && (
                            <div>
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>Upload</div>
                            </div>
                        )}
                    </Upload>
                </Form.Item>

                <Form.Item label="Descripcion">
                <Input.TextArea
                        rows={4}
                        onChange={handleInputChange}
                        name="description"
                        placeholder="Descripcion"
                        value={inputPrograma.description}
                    />
                </Form.Item>

                <Form.Item label="Fecha">
                <DatePicker
                        format="DD/MM/YYYY"
                        onChange={handleDateChange}
                        value={inputPrograma.date ? moment(inputPrograma.date, "DD/MM/YYYY") : null}
                    />
                </Form.Item>

                <Form.Item >
                <Button type="primary" htmlType="submit">
                        Guardar
                    </Button>
                </Form.Item>
            </Form>
        </div >
    );
};

export { FormularioCrearPrograma };

