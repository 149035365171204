import React from 'react';
import './Nosotros.css';

const Nosotros = () => {
  return (
    <div className="nosotros-container">
      <div className="nosotros-content">
        <p>
          El 1º de setiembre de 1915 salió a la calle el primer número de la que hoy es la publicación madre de MULTIMEDIOS LA IDEA. Surgió como una necesidad de la zona, en una época en la que los medios de comunicación eran escasos y hoy es uno de los medios más antiguos de nuestra provincia y de nuestro país.
        </p>
        <p>
          Siempre teniendo por norte la independencia política y el localismo informativo y con una conducta intachable que nos ha permitido superar el siglo de existencia. Esa continuidad y ese camino sin claudicaciones constituyen nuestro principal aval.
        </p>
        <p>
          Fundado por Mauricio Parada, el nombre de la publicación (que también da nombre a nuestro multimedios) se debe a que la imprenta propiedad de nuestro fundador se llamaba LA IDEA.
        </p>
        <p>
          Desde el 1º de setiembre de 1915 hasta el 9 de setiembre de 1916 el periódico se llamó TRIBUNA y luego cambió el nombre por LA IDEA. Don Mauricio lo tuvo en sus manos hasta 1940, pasando en 1941 a manos de Valentín Corcuera, quien lo cedió a su hijo Hugo en 1985, pasando en 1989 al actual director, el Lic. Pablo Adrián Corcuera.
        </p>
        <p>
          El Periódico LA IDEA es reconocido y respetado por todos, ya que ha logrado resistir las presiones políticas y los avatares económicos, lo que le ha permitido superar el siglo de existencia y mantenerse incólume, camino a su segundo centenario.
        </p>
        <p>
          <strong>Características técnicas</strong><br/>
          Mensual. Tabloide. Papel diario. Blanco y negro. Tirada: 12.000 ejemplares. Ámbito de influencia: todo el distrito de Lanús y la zona norte del distrito de Lomas de Zamora.
        </p>
      </div>
    </div>
  );
}

export default Nosotros;